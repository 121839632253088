//$color__yellow: #f7e767;
//$color__blue: #3c5c9e;
//$color__white: #fff;

$color__error: #e46363;
$color__correct: #a4d65e;

$inactive_opacity: 0.2;

$breakpoint_lg: 1300px;

$white: #fff;
$gray-100: $white;
$gray-200: rgba(0, 0, 0, 0.2) !default;
$gray-300: #c4c4c4;
$gray-400: rgba(#e5e5e5, 0.5);
//$gray-300: #dee2e6 !default;
//$gray-400: #ced4da !default;
//$gray-500: #adb5bd !default;
//$gray-600: #6c757d !default;
//$gray-700: #495057 !default;
//$gray-800: #343a40 !default;
//$gray-900: #212529 !default;
$black: #000;

$primary: #382766;
$primary_light: #302450;
$secondary: #e6e4f9;

$yellow: #fdca56;

$border-radius: 0;
