.Button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: Montserrat, sans-serif;
  cursor: pointer;

  line-height: 2.5;
  color: $primary;
  background-color: $white;
  padding-left: 2rem;
  padding-right: 2rem;

  &:hover {
    color: $primary;
    text-decoration: none;
  }

  &:focus {
    color: $primary;
    background-color: $white;
  }

  // For elements replacing buttons, to avoid jumps
  &-height,
  &-half-height {
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
  }

  &-height {
    line-height: 2.55;
  }

  &-half-height {
    line-height: 1.275;

    span {
      &:first-child {
        font-weight: bold;
      }
    }
  }

  &--play {
    &::after {
      font: normal normal normal 14px/1 FontAwesome;
      content: '\f04b';
      margin-left: 0.5rem;
    }
  }

  &--next {
    &::after {
      font: normal normal normal 14px/1 FontAwesome;
      content: '\f061';
      margin-left: 0.5rem;
    }
  }

  &--prev {
    background: transparent;
    border-color: transparent;

    &:hover {
      background: transparent;
      border-color: transparent;
    }

    &::after {
      font: normal normal normal 14px/1 FontAwesome;
      content: '\f060';
    }
  }

  &--abs {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}

