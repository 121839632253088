@import "vars";
//@import "~bootstrap/scss/bootstrap.scss";
@import "~reset-css";
@import "fonts";

// Sane resets

body {
  user-select: none;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: unset;
  overflow: hidden;
}

*, *::before, *::after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

@import "layout";
@import "buttons";
@import "fixes";
@import "game";
@import "svg-bg";

// Media lock (TODO maybe use JS classes for this one?)

@media (orientation: landscape) {
  .portrait-lock {
    display: none;
  }
}

@media (orientation: portrait), (max-width: 1000px) {
  .portrait-lock {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $primary;
    color: $yellow;

    img {
      margin-bottom: 1rem;
    }

    span {
      font-size: 2rem;

      max-width: 80%;
      text-align: center;
      line-height: 1.5;
    }
  }
}

