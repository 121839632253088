.text-bold {
  font-family: Montserrat, sans-serif;
}

.text-bolditalic {
  font-family: Montserrat, sans-serif;
}

.text-light {
  font-family: Montserrat, sans-serif;
}

.text-lightitalic {
  font-family: Montserrat, sans-serif;
}

.text-medium {
  font-family: Montserrat, sans-serif;
}

.text-mediumitalic {
  font-family: Montserrat, sans-serif;
}

.text-regular {
  font-family: Montserrat, sans-serif;
}

.text-regularitalic {
  font-family: Montserrat, sans-serif;
}

.text-semibold {
  font-family: Montserrat, sans-serif;
}

.text-semibolditalic {
  font-family: Montserrat, sans-serif;
}

.text-tilting-black {
  font-family: Montserrat, sans-serif;
}

.text-tilting-bold {
  font-family: Montserrat, sans-serif;
}

.text-tilting-light {
  font-family: Montserrat, sans-serif;
}

.text-tilting-regular {
  font-family: Montserrat, sans-serif;
}

.text-tilting-semibold {
  font-family: Montserrat, sans-serif;
}

