// Cards

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.icon-extraction {
  background-image: url('./svg/cards/material-extraction.svg');
}

.icon-production {
  background-image: url('./svg/cards/Production of the components.svg');
}

.icon-assembly {
  background-image: url('./svg/cards/Assembly of the device.svg');
}

.icon-electricity-active {
  background-image: url('./svg/cards/electricity consumption Active.svg');
}

.icon-electricity-passive {
  background-image: url('./svg/cards/electricity consumption Passive.svg');
}

.icon-electricity-data {
  background-image: url('./svg/cards/Elecricity consumption data.svg');
}

.icon-distribution {
  background-image: url('./svg/cards/distribution of products.svg');
}

.icon-shopping {
  background-image: url('./svg/cards/shopping trips.svg');
}

.icon-stores {
  background-image: url('./svg/cards/stores and franchise.svg');
}

.icon-electricity-networks {
  background-image: url('./svg/cards/Elecricity consumption networks.svg');
}

