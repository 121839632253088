@import "vars";

.container-vertical {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen  and (min-width: $breakpoint_lg){
  .container {
    max-width: 1600px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  flex-grow: 1;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.intro-text {
  max-width: 32rem;
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  line-height: 1.4;
}

